@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Orbitron;
  src: url(fonts/Orbitron-Medium.ttf);
}

@font-face {
  font-family: Rajdhani;
  src: url(fonts/Rajdhani-Medium.ttf);
}

@font-face {
  font-family: SourceCodePro;
  src: url(fonts/SourceCodePro-Medium.ttf);
}

.btn {
  background: linear-gradient(135deg, #c53830 10%, #680b0b 100%);
  box-shadow: 0px 4.42184px 107.23px rgba(87, 16, 16, 0.621);
}

.btn:hover {
  background: linear-gradient(135deg, #680b0b 10%, #c53830 100%);
}

.gradient,
.active {
  background: linear-gradient(135deg, #c53830 10%, #680b0b 100%);
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#c53830] to-[#680b0b] font-bold font-primary hover:from-[#680b0b] hover:to-[#c53830];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #000000;
}

::-webkit-scrollbar-thumb {
  background: #680b0b;
}

::-webkit-scrollbar-thumb:hover {
  background: #c53830;
}
